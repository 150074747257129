type TextAlignment = 'left' | 'center' | 'right';
export type ReportKey =
  | 'current-status'
  | 'current-user-reservations'
  | 'current-station-connectivity'
  | 'station-use'
  | 'station-user-use'
  | 'popular-usage-times';

interface CustomerReport {
  reportName: string;
  reportKey: ReportKey;
  canExport: boolean;
}
export interface TableReport extends CustomerReport {
  columns: ReportColumn[];
}

export interface ReportColumn {
  header: string;
  dataKey: string;
  width: string;
  linkId?: string;
  align?: TextAlignment;
  description?: string;
}

export const TABLE_REPORT_LIST: TableReport[] = [
  {
    reportName: 'Current bay state',
    reportKey: 'current-status',
    canExport: true,
    columns: [
      { header: 'Node', dataKey: 'groupName', linkId: 'groupId', width: '25%' },
      { header: 'Station', dataKey: 'stationName', linkId: 'stationId', width: '25%' },
      { header: 'Bay name', dataKey: 'bayName', linkId: 'bayId', width: '25%' },
      { header: 'Bay number', dataKey: 'bayId', align: 'center', width: '20%' },
      { header: 'Bay status', dataKey: 'status', width: '25%' }
    ]
  },
  {
    reportName: 'Current user reservations',
    reportKey: 'current-user-reservations',
    canExport: true,
    columns: [
      { header: 'User', dataKey: 'userName', linkId: 'userId', width: '25%' },
      { header: 'Node', dataKey: 'groupName', linkId: 'groupId', width: '25%' },
      { header: 'Station', dataKey: 'stationName', linkId: 'stationId', width: '25%' },
      { header: 'Bay name', dataKey: 'bayName', linkId: 'bayId', width: '25%' },
      { header: 'Bay number', dataKey: 'bayId', align: 'center', width: '20%' },
      { header: 'Bay status', dataKey: 'status', width: '25%' }
    ]
  },
  {
    reportName: 'Current station connectivity',
    reportKey: 'current-station-connectivity',
    canExport: true,
    columns: [
      { header: 'Station', dataKey: 'stationName', linkId: 'stationId', width: '70%' },
      { header: 'Connected', dataKey: 'connected', width: '30%' }
    ]
  },
  {
    reportName: 'Station usage',
    reportKey: 'station-use',
    canExport: true,
    columns: [
      { header: 'Node', dataKey: 'groupName', linkId: 'groupId', width: '25%' },
      { header: 'Station', dataKey: 'stationName', linkId: 'stationId', width: '25%' },
      {
        header: 'Bay reserved',
        dataKey: 'bayCredsCached',
        align: 'center',
        width: '15%',
        description: 'When a user has reserved a bay'
      },
      {
        header: 'Bay accessed',
        dataKey: 'bayCredsCleared',
        align: 'center',
        width: '15%',
        description: 'When a user has accessed their reserved bay'
      },
      {
        header: 'Bay unlocked',
        dataKey: 'bayUnlock',
        align: 'center',
        width: '15%',
        description: 'When a bay door has been opened through the portal'
      },
      {
        header: 'Bay offline',
        dataKey: 'bayOffline',
        align: 'center',
        width: '15%',
        description: 'When a bay has entered offline state'
      },
      {
        header: 'Bay breached',
        dataKey: 'bayBreach',
        align: 'center',
        width: '15%',
        description: 'When a bay has been manually forced open'
      },
      {
        header: 'Bay stuck',
        dataKey: 'bayStuck',
        align: 'center',
        width: '15%',
        description: 'When a bay fails to open due to an obstruction or jam'
      }
    ]
  },
  {
    reportName: 'Station usage by user',
    reportKey: 'station-user-use',
    canExport: true,
    columns: [
      { header: 'User', dataKey: 'userName', linkId: 'userId', width: '70%' },
      {
        header: 'Bay reserved',
        dataKey: 'bayCredsCached',
        align: 'center',
        width: '15%',
        description: 'When a user has reserved a bay'
      },
      {
        header: 'Bay accessed',
        dataKey: 'bayCredsCleared',
        align: 'center',
        width: '15%',
        description: 'When a user has accessed their reserved bay'
      }
    ]
  }
];

export const POPULAR_USAGE_TIMES_REPORT: CustomerReport = {
  reportName: 'Popular usage times',
  reportKey: 'popular-usage-times',
  canExport: false
};

export const ALL_REPORTS_LIST: CustomerReport[] = [...TABLE_REPORT_LIST, POPULAR_USAGE_TIMES_REPORT];
